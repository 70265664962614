import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import PhotoItem from "../../../../Utils/PhotoItem";

const moment = require("moment");

const PAE = ({ data, active, ...props }) => {
    console.log("data===>",Object.keys(data),data.situation,data.site)
  const url = data && data.location ? `https://www.google.com/maps/place/${data.location.lat},${data.location.lng}/@${data.location.lat},${data.location.lng},15z` : "";

  if (data)
    return (
      <>
        
         <h6>PROCESS ET EQUIPEMTS</h6>
        <br />
        <h6> CAPACITE DE PRODUCTION </h6>
       
        <div className={"row col-md-6"}>
        <label>Capacité journaliére : </label>
        {data.Pae.capaciteJournaliere}
      </div>
      <div className={"row col-md-6"}>
        <label>Capacité annuelle : </label>
        {data.Pae.capaciteAnnuelle}
      </div>
      <h6>ACTIVITÉS EXERCÉES </h6>
        <div className={"row col-md-6"}>
            <label>Activité : </label>
            {data.Pae.acivite}
        </div>
        <div className={"row col-md-6"}>
        <label>Durée d'activité : </label>
            {data.Pae.dureeActivite}
         </div>
         <div className={"row col-md-6"}>
        <label>Saison du Pic de production : </label>
            {data.Pae.saisonProduction}
         </div>
         <div className={"row col-md-6"}>
            <label>Période de mise à la vente  : </label>
            {data.Pae.periodeMiseVente}
         </div>
         <div className={"row col-md-6"}>
            <label>Produits finis  : </label>
            {data.Pae.produitsFinis}
         </div>
         <div className={"row col-md-6"}>
            <label> Projets d’extension  : </label>
            {data.Pae.projetExtension}
         </div>
         <h6>   MATIERES PREMIERES </h6>
         <div className={"row col-md-6"}>
            <label> Matière  : </label>
            {data.Pae.Matière}
         </div>
         <div className={"row col-md-6"}>
            <label> Nature  : </label>
            {data.Pae.Nature}
         </div>
         <div className={"row col-md-6"}>
            <label> Forme  : </label>
            {data.Pae.Forme}
         </div>
         <div className={"row col-md-6"}>
            <label> Fournisseur (Pays)  : </label>
            {data.Pae.Fournisseur}
         </div>
        <h6>PROCESSUS DE PRODUCTION </h6>
        <div className={"row col-md-6"}>
            <label> Nombre de ligne de production : </label>
            {data.Pae.ligneProduction}
         </div>
         <h6>CONTROLE DE QUALITE </h6>
         <div className={"row col-md-6"}>
            <label> Laboratoire : </label>
            {data.Pae.laboratoire}
         </div>
         <div className={"row col-md-6"}>
            <label> Les différents contrôles  : </label>
            {data.Pae.controleQualite}
         </div>
         <div className={"row col-md-6"}>
            <label> Prélévement : </label>
            {data.Pae.Prélévement}
         </div>


        <h6>PRINCIPAUX EQUIPEMENTS </h6>
        <div className={"row col-md-6"}>
            <label> Equipements : </label>
            {data.Pae.Equipements}
         </div>
         <div className={"row col-md-6"}>
            <label> Marques : </label>
            {data.Pae.Marques}
         </div>
         <div className={"row col-md-6"}>
            <label> N° de série : </label>
            {data.Pae.numeroSerie}
         </div>
         <div className={"row col-md-6"}>
            <label> Année d’acquisition : </label>
            {data.Pae.anneeAcquisition}
         </div>
         <div className={"row col-md-6"}>
            <label> Valeurs d’acquisition DA : </label>
            {data.Pae.valeurAcquisition}
         </div>


      </>
    );
  else return <>Didn't clock in</>;
};

export default PAE;
