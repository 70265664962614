import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';

import Select from 'react-select';

import * as actions from '../../Redux/actions/index';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {  faTimes, faPowerOff, faPen, faTrash, faPlay } from '@fortawesome/free-solid-svg-icons'
import api from '../../Utils/Api';
import SocketContext from '../../Utils/socket-context';

import {  Trans } from "react-i18next";
import Pagination from '../../Utils/Pagination';
import UserEditOverlay from '../User/UserEditOverlay';


const mapStateToProps = state => {
	return {
		auth: state.session.auth,
		user: state.session.user,
		filters: state.filters
	}
}

const mapDispatchToProps = dispatch => {
	return {
		setAuth: user => dispatch(actions.setAuthAction(user)),
		isLoading: e => dispatch(actions.startLoading(e)),
		doneLoading: () => dispatch(actions.doneLoading()),
		setFlash: (f) => dispatch(actions.setFlash(f)),
    	closeFlash: (f) => dispatch(actions.closeFlash(f))
	}
}

const moment = require("moment");


class CManagerList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			users: [],
			filters: {
				status: '',
				name: '',
				cluster: "",
				status: ""
			},
			tableIsLoading: true,
			limit: 15,
			pages: 1,
			current: 1,
			showOverlay: false,
			selectedUser: 0,
			lastUpdate: 0,
			showOverlayRenew: false,
			renewDuration: 1,
			selected_user: {},
			showEditOverlay: false

		}

		this.props.isLoading();

		this.loadAgents = this.loadAgents.bind(this);
		this.loadAgents =debounce(this.loadAgents, 300)
		this.selectPage = this.selectPage.bind(this);
		this.userDeleteBtn = this.userDeleteBtn.bind(this);
		this.toggleOverlay = this.toggleOverlay.bind(this);
		this.toggleOverlayRenew = this.toggleOverlayRenew.bind(this);
		this.deleteUser = this.deleteUser.bind(this);

		this.agentInterval = null;
		this.updateFilter = this.updateFilter.bind(this);
		
		this.changeLimit = this.changeLimit.bind(this);

		this.submitRenewal = this.submitRenewal.bind(this);
	}

	toggleEditOverlay = () => this.setState({showEditOverlay: !this.state.showEditOverlay})
	changeDuration(e) {
		this.setState({
			renewDuration: e.value
		})
	}
	submitRenewal(e) {
		e.preventDefault();
		const that = this;

		const data = {
			userid: this.state.selectedUser,
			duration: this.state.renewDuration
		}
		
		if(!data.duration || parseInt(data.duration) === 0)
			return;

		api.post('/api/user/'+this.state.selectedUser+'/renew', data)
		.then(function(response) {
			if(response.data)
				console.log(response.data)
		})
		
	}
	changeLimit(e) {

		this.props.isLoading();

		
		this.setState({
			limit: e.value,
			current: 1
		}, () => this.loadAgents())

		

	}

	selectPage(p) {
		this.props.isLoading();
		this.setState({current: p});
		this.loadAgents(p);
	}

	getTimestamp() {
		var now = new Date();
		return parseInt(now.getTime()/1000)
	}

	componentDidMount() {

		const that = this;

		this.loadAgents();


	}

	componentWillUnmount() {
		//clearInterval(this.agentInterval)

		if(this.props.socket) {
			this.props.socket.off('test')
		}
	}

	toggleOverlay(e) {
		this.setState({showOverlay: !this.state.showOverlay});
	}
	toggleOverlayRenew(e, user) {

		this.setState({
			showOverlayRenew: !this.state.showOverlayRenew,
			selectedUser: user ? user : this.state.selectedUser
		});
	}


	loadAgents(p = this.state.current) {
		const that = this;
		

		this.setState({
			lastUpdate: this.getTimestamp()
		}, function() {

			const crits = "name="+that.state.filters.name
						  +"&status="+that.state.filters.status
						  +"&cluster="+that.state.filters.cluster
						  +"&status="+that.state.filters.status
						  +"&limit="+that.state.limit

			api.get('/api/clustermanager/fetch?'+crits)
			.then(function(response) {
				if(response.data.success) {
					
					that.setState(response.data);
				} else {
					//that.props.setAuth(response.data);
				}

				that.props.doneLoading();
			});
		});

		
	}
 
	userDeleteBtn = (id) => {
		
		this.setState({
			selectedUser: id
		})
		

		this.toggleOverlay();

	}

	deleteUser(e) {

		let that = this;
		const data = { 
			user: this.props.user
		}

		that.toggleOverlay();
		that.props.isLoading();
		api.post('/api/user/delete/'+this.state.selectedUser, data)
		.then(function(response) {
			
			

			if(response.data.success) {
				//that.loadAgents();
				that.props.setFlash({
					active: true,
					message: "User has been succesfully deleted.",
					classes: "success",
					style: {}
				})
			} else {
				that.props.setFlash({
					active: true,
					message: "Failed to delete user : "+response.data.error,
					classes: "warning",
					style: {}
				})
			}

			that.props.doneLoading();
			that.loadAgents();
		});
		
	}

	updateFilter(e, name) {

		let that = this;

		const value = e.target ? e.target.value : e.value;


		this.setState({
			filters: {
				...this.state.filters, 
				[name]: value
			}
		}, function() {
			that.props.isLoading();
			that.loadAgents(1);
		});
	}


	enableUser = (userid) => {

		this.props.isLoading({
			message: "Mise à jour en cours",
			animated: true
		})

		api.post('/api/user/enable/'+userid)
		.then(response => {
			if(response.data.success) {
				console.log('user disabled')
			}
		})
		.then(res => {
			this.props.doneLoading();
			this.loadAgents();
		})

	} 

	disableUser = (userid) => {
		this.props.isLoading({
			message: "Mise à jour en cours",
			animated: true
		})

		api.post('/api/user/disable/'+userid)
		.then(response => {

			if(response.data.success) {
				console.log('user enabled')
			}			
		})
		.then(res => {
			this.props.doneLoading();
			this.loadAgents();
		})
	}

	selectUser = (user) => {
		console.log(user)
		this.setState({
			selected_user: user,
			showEditOverlay: true
		})
	}

	render() {
		const toggleOverlay = this.toggleOverlay;
		const toggleOverlayRenew = this.toggleOverlayRenew;
		const showOverlay = this.state.showOverlay;
		const showOverlayRenew = this.state.showOverlayRenew
		const data = this.state;
		return(
			<div>
			
			<UserEditOverlay
				reload={this.loadAgents}
				role={"cluster_manager"}
				open={this.state.showEditOverlay}
				selected_user={this.state.selected_user}
				toggleEditOverlay={this.toggleEditOverlay}
				{...this.props}
			/>

			{!this.props.auth ? <Redirect to="/login" /> :  ""}

			<div className={showOverlay ? "ui-overlay open" : "ui-overlay"}>
				<div className={"ui-overlay-content delete-overlay col-md-4 offset-md-4"}>
					<button onClick={e => { toggleOverlay(e); } } className={"ui-overlay-close btn btn-danger btn-sm"}>
					<FontAwesomeIcon icon={faTimes} />
					</button>

					Are you sure you want to delete this user?<br />
					This action is irreversible
					<div className={"delete-buttons"}>
					<button  onClick={e => { toggleOverlay(e); } } className={"btn btn-info"}>No</button><button onClick={this.deleteUser} className={"btn btn-danger ml-2"}>Delete</button>
					</div>
				</div>
			</div>


			<div className={"container-header"}>
					Admin (Cluster Managers)
			</div>
			
			<div className={"row"}>
			
			<div className={"col-md-2"}>
				<div className={"side-filters"}>

					{this.props.user.role === 'admin' && (
						<Link to={"/clustermanagers/new"}  className={"btn btn-primary btn-block my-2"}>Nouveau Admin  (cluster manager)</Link>
					)}
					<h4>Recherche</h4>

					<div className={"form-group"}>
						<label><Trans i18nKey="label_name" /></label>
						<input onChange={e => this.updateFilter(e, 'name')} value={this.state.filters.name} name="name" type="text" className={"form-control"} />
					</div>

					<div className={"form-group"}>
						<label>Cluster</label>
						<Select
							onChange={v => this.updateFilter(v, 'cluster')}
							options={this.props.filters.clusters}
							classNamePrefix={"custom-select"}
						/>
					</div>

					<div className={"form-group"}>
						<label>Status</label>
						<Select
							onChange={v => this.updateFilter(v, 'status')}
							options={this.props.filters.account_status}
							classNamePrefix={"custom-select"}
						/>
					</div>


				</div>
			</div>

			<div className={"col-md-10"}>

				<div className={"row mb-3"}>
					<div className={"col-md-6 items-found"}>
						<p>
							<b>{this.state.total}</b> Cluster Managers trouvés
						</p>
					</div>
					<div className={"col-md-6 per-page-wrap"}>
						<div className={"per-page-content"}>
							<p>Résultat par page</p> 
							<Select
								classNamePrefix={"custom-select"}
								onChange={this.changeLimit}
								className={"per-page nm"}
								options={this.props.filters.result_per_page}
								value={{label: this.state.limit, value: this.state.limit}}
							/>
						</div>
						<Pagination
							data={this.state}
							handleClick={this.selectPage} 
						/>
						
					</div>

				</div>

				<div className="col-md-12 ctable">
				
				
				<div className={"d-none d-sm-block"}>
					<div className={"row head-titles ctable-row"}>
						<div className={"col-md-3"}>
							Admin (Cluster Manager)
						</div>
						<div className={"col-md-2"}>
							Cluster
						</div>
						<div className={"col-md-2"}>
							<Trans i18nKey="user_email" />
						</div>
						<div className={"col-md-1 small-number-col"}>
							Sites	
						</div>
						<div className={"col-md-1 small-number-col"}>
							Demandeurs
						</div>
						<div className={"col-md-1 small-number-col"}>
							Ingenieurs
						</div>

						<div className={"col-md-2 task-details-col"}>
							Actions
						</div>
					</div>

				</div>


				{
					this.state.users.map((user, j) => (


					<div className={"row"} key={j}>

						<div className={"col-md-12"}>

						<div className={"row ctable-row"}>
							<div className={"col-md-3"}>
								<div className={"agent-list-profile"}>

									<div className={"col-profile-ct"}>

										
										<span className={"agent-list-username"}>
										<span>{user.username}</span>
										
											{/* <span className={"status-id-1"}><abbr>  </abbr></span> */}
									
										</span>

									</div>

								</div>

							</div>
							
							<div className={"col-md-2"}>
								{user.title}
							</div>

							<div className={"col-md-2"}>
								{user.email}
							</div>
							<div className={"col-md-1 small-number-col"}>
								{user.sites}

							</div>

							<div className={"col-md-1 small-number-col"}>
								{user.managers_count}
							</div>

							<div className={"col-md-1 small-number-col"}>
								{user.agents_count}
							</div>


							
							<div className={"col-md-2 task-details-col"}>
							{user.status !== 'deleted' ? (
								<>
								<button onClick={() => this.selectUser(user)} className={"task-action-btn"}><FontAwesomeIcon icon={faPen}  /> Modifier</button>
								{user.status === "disabled" 
									? <button onClick={e => this.enableUser(user.userid)}  className={"task-action-btn ml-2 "}><FontAwesomeIcon icon={faPlay}  /> Activer</button>
									: <button onClick={e => this.disableUser(user.userid)}  className={"task-action-btn ml-2 "}><FontAwesomeIcon icon={faPowerOff}  /> Désactiver</button>
								}
								<button onClick={e => this.userDeleteBtn(user.userid) } className={"task-action-btn ml-2 "}><FontAwesomeIcon icon={faTrash}  /> Supprimer</button>
								</>
							) : <b>ACCOUNT DELETED</b>}
							</div>

								
							
							
						</div>

						</div>
					</div>
				))
				}

				{this.state.users.length === 0 && (<div className={"col-md-12 text-center my-3"}>Aucun Cluster manager trouvé</div>) }




			</div>
				
			</div>

			</div>

			</div>
		)
	}
}



const CManagerListConsumer = props => (
	<SocketContext.Consumer>
		{socket => <CManagerList {...props} socket={socket} />}
	</SocketContext.Consumer>
  )
	

export default connect(mapStateToProps, mapDispatchToProps)(CManagerListConsumer)


