import ClockIn from "./ClockIn";
import Introduction from "./Introduction";
import Situation from "./Situation";
import Maintenance from "./Maintenance";
import PAE from "./PAE";
import Surete from "./Surete";
import PAP from "./PAP";
import utilities from "./Utilities";
import Stockage from "./Stockage";
import Construction from "./Construction";
const SSRTests = [
  {
    name: "Clock-in",
    key: "clockin",
    component: ClockIn,
  },
  {
    name: "Introduction",
    key: "introduction",
    component: Introduction,
  },
  {
    name: "Maintenance",
    key: "maintenance",
    component: Maintenance,
  },
  {
    name: "Situation",
    key: "situation",
    component: Situation,
  },
  {
    name: "Construction",
    key: "construction",
    component: Construction,
  },
  {
    name: "Process and equipements",
    key: "PAE",
    component: PAE,
  },
  {
    name: "Stocakge",
    key: "stockage",
    component: Stockage,
  },
  {
    name: "Surete",
    key: "surete",
    component: Surete,
  },

  {
    name: "Utilities",
    key: "Utilites",
    component: utilities,
  },
  {
    name: "Prevention and protection",
    key: "PAP",
    component: PAP,
  },
];

export default SSRTests;
