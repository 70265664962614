import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setAuthAction, startLoading, doneLoading, setFlash, closeFlash } from '../../../Redux/actions/index';
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import api from '../../../Utils/Api';
import ReturnOverlay from '../components/ReturnOverlay';
import RejectOverlay from '../components/RejectOverlay';
import AddDataOverlay from '../components/AddDataOverlay';
import Constants from '../../../Utils/Constants';
import TaskRow from './TaskRow';
import ApiService from '../../../Utils/ApiService';
import Select from "react-select";


const mapStateToProps = state => {
	return {
		session: state.session
	}
}

const mapDispatchToProps = (dispatch) =>
({
	isLoading: e => dispatch(startLoading(e)),
	doneLoading: () => dispatch(doneLoading()),
	setAuth: e => dispatch(setAuthAction(e)),
	setFlash: (f) => dispatch(setFlash(f)),
	closeFlash: (f) => dispatch(closeFlash(f))
})

// const structure_type = ['GF SST 3L', 'RT TOWER', 'GF SST 4L',  'GF MONO', 'RT POLE', 'GF PALM', 'GF POLE', 'RT WMPOLE', 'COW', 'GF GMAST', 'RT GMAST', 'IBS', 'GF GMAST', 'RT GMAST', 'Small Cells']
// var tab = Array(4).fill(0).map(() => new Array(15).fill(''))
// tab.unshift(structure_type)

var tab = Array(1).fill(0).map(() => new Array(5).fill(''))

class TaskList extends Component {

	constructor(props) {

		super(props);

		this.state = {
			sites: [],
			selected_type: "delivery",
			files: [],
			file_id: '',
			rows: [],
			showOverlay: false,
			taskid: "",
			agentid: "",
			selected_pm: "",
			data_m_selected: tab,
			activeTest: "clockin",
			showDeleteOverlay: false,
			showDeleteOverlayConfirmation: false,
			showReturnOverlay: false,
			showRejectOverlay: false,
			showAddDataOverlay: false, 
			showNewReplanOverlay: false
		}
	}

	componentDidMount() {


		api
			.get("/api/sites/filters")
			.then((response) => {
				if (response.data.success) {
					console.log("hello 2", response.data.agents);

					this.setState({ agents: response.data.agents });

					console.log("hello 3", response.data.agents);

				}
			});
		api.get("/api/sites/fetch/filter?limit=-1")
			.then((response) => {
				if (response.data.success) {
					this.setState({ sites: response.data.sites })
					console.log("site getting", response.data.sites);

				}
			});
		// api.get("/api/task_type/fetch")
		// .then((response) => {
		//   if (response.data.success) {


		// 	this.setState({types:response.data.tasks

		// 	})

		// 	console.log("hello tasks ",response.data.tasks);
		//   } });
		console.log("site getting", this.state.sites);

	};

	toggleOverlay = () => this.setState({ showOverlay: !this.state.showOverlay });

	selectTest = (e, test) => {

		if (window.ga)
			window.ga('send', 'event', 'TaskList', 'selectTest');

		e.preventDefault();
		this.setState({ activeTest: test })
	}

	deleteTask = (pmid) => {

		//const pmid = e.target.getAttribute('data-task-id');

		this.setState({
			selected_pm: pmid,
			showDeleteOverlay: true,
			deleteSteps: 1
		})
	}

	nextDeleteStep = () => {

		this.setState({
			showDeleteOverlay: false,
			showDeleteOverlayConfirmation: true,
			deleteSteps: 2
		})
	}

	confirmDelete = () => {

		this.props.isLoading();
		this.setState({ showDeleteOverlayConfirmation: false })

		const data = {
			pmid: this.state.selected_pm
		}

		api.post('/api/pm/delete', data)
			.then(() => {
				this.props.handlers.loadTasks()
			})
	}

	exportPM = (pm, anomalies) => {

		console.log("hello here", pm);

		if (window.ga)
			window.ga('send', 'event', 'TaskList', 'exportPM');

		// let remote = Constants.apiURL;

		if (anomalies) {
			this.pmExportForm.action = Constants.apiURL + "/api/anomalies/checks_export/" + pm.task_id;
		} else {
			this.pmExportForm.action = Constants.apiURL + "/api/pm/export/igeneral/" + pm.task_id;
			//this.pmExportForm.action = Constants.apiURL+"/api/pm/export/pickup/"+pm.task_id;
		}

		this.pmInput.value = JSON.stringify(pm)
		this.userInfo.value = JSON.stringify(this.props.session.user)
		this.props.isLoading();
		this.pmExportForm.submit();
		this.props.doneLoading();
	}

	toggleReturnOverlay = () => this.setState({ showReturnOverlay: !this.state.showReturnOverlay })
	toggleRejectOverlay = () => this.setState({ showRejectOverlay: !this.state.showRejectOverlay })
	toggleAddDataOverlay = () => this.setState({ showAddDataOverlay: !this.state.showAddDataOverlay })

	returnTask = (task) => {

		this.setState({
			selected_pm: task,
			showReturnOverlay: true
		})
	}

	closeTask = taskid => {

		this.props.isLoading({ message: "Mise a jour en cours." });

		ApiService.closeTask(taskid)
			.then(res => {
				this.props.handlers.loadTasks();
				this.props.doneLoading();
			})
	}

	acceptTask = taskid => {

		this.props.isLoading({ message: "Mise a jour en cours." });

		ApiService.acceptTask(taskid)
			.then(res => {
				this.props.handlers.loadTasks();
				this.props.doneLoading();
			})
	}
	rejectTask = (task)=> {

		// this.props.isLoading({ message: "Mise a jour en cours." });

		// ApiService.rejectTask(taskid)
		// 	.then(res => {
		// 		this.props.handlers.loadTasks();
		// 		this.props.doneLoading();
		// 	})
		this.setState({
			selected_pm: task,
			showRejectOverlay: true
		})
	}

	// showNewDelivery = (taskid) => {
	// 	this.setState({
	// 		showNewDeliveryOverlay: true,
	// 		dataSent: {
	// 			...this.state.dataSent,
	// 			taskid: taskid
	// 		}
	// 	})
	// }

	showNewReplan= (taskid) => {
		this.setState({
			showNewReplanOverlay: true,
			dataSent: {
				...this.state.dataSent,
				taskid: taskid
			}
		})
	}

	addDataTask = (task, data) => {
		console.log('show m data -> ' + data)
		this.setState({
			selected_pm: task,
			data_m_selected: data !== null ? JSON.parse(data) : tab,
			showAddDataOverlay: true
		})
	}

	newDelivery = () => {
		this.setState({ showNewDeliveryOverlay: false })

		let data = { task_id: this.state.taskid, agent_id: this.state.agentid }
		api.post('/api/planning/installation', this.state.dataSent).then(result => {
			if (result.data.success) {
				console.log("true", result.data.res)
				this.setState({ alert_message: "success" });
				setTimeout(() => { this.setState({ alert_message: "" }); }, 2000);
				
			} else {
				this.setState({ alert_message: "Error" });
			}
		})

		
	}

	replanTask = () => { 
		this.setState({ showNewReplanOverlay: false })

		this.props.isLoading({ message: "Mise a jour en cours." });

		ApiService.replanTask(this.state.dataSent)
			.then(res => {
				this.props.handlers.loadTasks();
				this.props.doneLoading();
			})
	} 

	updateAttState = (event, name) => {

		//let value = "";

		const value = event.target ? event.target.value : event.value;

		this.setState(
			{
				dataSent: {
					...this.state.dataSent,
					[name]: value
				}
			}
		);

	};

	uploadFile = ({ target: { files } }) => {
		console.log("files", files[0])
		let data = new FormData()

		data.append('file', files[0]);
		console.log('data files', data);
		api.post("/api/settings/files/new", (data))
			.then((response) => {
				console.log('data response', response);

				if (response.data.success) {

					//console.log('file_id',this.state.file_id);
					this.setState({ file_id: response.data.file_id });

				}

			})
	}
	render() {

		const data = this.props.data;
		const fileid = this.state.file_id;

		return (
			<React.Fragment>
				<ReturnOverlay
					open={this.state.showReturnOverlay}
					task={this.state.selected_pm}
					toggle={this.toggleReturnOverlay}
					reload={this.props.handlers.loadTasks}
					{...this.props}
				/>

				<RejectOverlay
					open={this.state.showRejectOverlay}
					task={this.state.selected_pm}
					toggle={this.toggleRejectOverlay}
					reload={this.props.handlers.loadTasks}
					{...this.props}
				/>

				<AddDataOverlay
					open={this.state.showAddDataOverlay}
					task={this.state.selected_pm}
					toggle={this.toggleAddDataOverlay}
					reload={this.props.handlers.loadTasks}
					data_m={this.state.data_m_selected}
					{...this.props}
				/>

				<form method="post" ref={ref => this.pmExportForm = ref}>
					<input type="hidden" name={"pm"} ref={ref => this.pmInput = ref} />
					<input type="hidden" name={"token"} value={this.props.session.user.token} />
					<input type="hidden" name={"userInfo"} ref={ref => this.userInfo = ref} />
				</form>

				<div className={this.state.showDeleteOverlay ? "ui-overlay delete-task open" : "ui-overlay delete-task"}>
					<div className={"ui-overlay-content status-overlay-text col-md-4 offset-md-4"}>
						<button
							onClick={() => this.setState({ showDeleteOverlay: false })}
							className={"ui-overlay-close btn btn-danger btn-sm"}>
							<FontAwesomeIcon icon={faTimes} />
						</button>
						<h5 className={"mb-4"}>Voulez-vous vraiment supprimer cette VDR ?</h5>
						<button type="submit" className={"btn btn-success btn-sm mt-2"}>ANNULER</button>
						<button onClick={this.nextDeleteStep} type="submit" className={"btn btn-danger btn-sm ml-2 mt-2"}>OUI, SUPPRIMER</button>
					</div>
				</div>

				<div className={this.state.showDeleteOverlayConfirmation ? "ui-overlay delete-task open" : "ui-overlay delete-task"}>
					<div className={"ui-overlay-content status-overlay-text col-md-4 offset-md-4"}>
						<button
							onClick={() => this.setState({ showDeleteOverlayConfirmation: false })}
							className={"ui-overlay-close btn btn-danger btn-sm"}>
							<FontAwesomeIcon icon={faTimes} />
						</button>
						<h5 className={"mb-5"}>Êtes-vous sur de vouloir supprimer cette VDR  ?</h5>
						<button onClick={() => this.setState({ showDeleteOverlayConfirmation: false })} type="submit" className={"btn btn-success btn-sm mt-2"}>Retour</button>
						<button onClick={this.confirmDelete} type="submit" className={"btn btn-danger btn-sm mt-2 ml-2"}>Confirmer la suppression</button>
						<div>
							<span className={"font-exp"}>Attention, cette action est irreversible.</span>
						</div>
					</div>
				</div>

				<div className={this.state.showNewReplanOverlay ? "ui-overlay return-task open" : "ui-overlay return-task"}>
					<div className={"ui-overlay-content status-overlay-text col-md-4 offset-md-4"}>
						<button
							onClick={() => this.setState({ showNewReplanOverlay: false })}
							className={"ui-overlay-close btn btn-danger btn-sm"}>
							<FontAwesomeIcon icon={faTimes} />
						</button>
						{/* <label className=" font-exp" >PickUp  site: </label>
						<select name={"pickup_site"} className={"form-control"}>
							{this.state.sites.map((site) => <option key={site.site_id} value={site.site_id}>{site.site_name}</option>
							)}
						</select>

						<label className={"font-exp"} >Drop site</label>
						<select name={"drop_site"} className={"form-control"}>
							{this.state.sites.map((site) => <option key={site.site_id} value={site.site_id}>{site.site_name}</option>
							)}
						</select>

						<div className={"form-group mt-3"}>
							<label className={"font-exp"}  >BOQ</label>
							<input type="hidden" name={"file_id"} value={fileid} />
							<input type="file" className="form-control delivery-file-uploder" onChange={this.uploadFile} />
						</div> */}

						<label>Assignier a un Ingenieur *</label>
						<Select
							classNamePrefix={"custom-select"}
							name={"agent"}
							options={this.state.agents}
							placeholder="Agent"
							className={"d-block mt-1"}
							onChange={e => this.updateAttState(e, "agentid")}
							required
						/>
						{/* <select required name={"agentid"}  className={"form-control"}  onChange={this.handleChangeSelected} >
            					{this.state.agents.map((agent)=> <option key={agent.label} value={agent.value}>{agent.label}</option>
            				)}
          					</select> */}
						<label>Date de plannification *</label>
						<input required type="date" className={"form-control"} onChange={e => this.updateAttState(e, "planning_date")} />

						{/* <label>Description</label>
						<textarea name="instruction" className={"form-control"} id="description" /> */}



						<button onClick={this.replanTask} type="submit" className={"btn btn-primary btn-sm ml-2 mt-2"}>Confirmer</button>


					</div>
				</div>

				<div className="col-md-12 ctable mb-3">
					<div className={"d-none d-sm-block"}>
						<div className={"row head-titles ctable-row"}>

							<div className={"col-md-1"}>
								ID
							</div>

							<div className={"col-md-2"}>
								<Trans i18nKey="label_agent" />
							</div>

							<div className={"col-md-1"}>
								Site
							</div>

							<div className={"col-md-2"}>
								Owner
							</div>

							<div className={"col-md-2"}>
								<Trans i18nKey="label_datetime" />
							</div>

							<div className={"col-md-2"}>
								Etapes SSR
							</div>

							<div className={"col-md-2 task-details-col"}>
								<Trans i18nKey="label_details" />
							</div>
						</div>
					</div>

					{data.tasks.map((task, i) =>
						<TaskRow
							key={task.task_id}
							exportPM={this.exportPM}
							task={task}
							handlers={this.props.handlers}
							deleteTask={this.deleteTask}
							returnTask={this.returnTask}
							closeTask={this.closeTask}
							acceptTask={this.acceptTask}
							rejectTask={this.rejectTask}
							showNewReplan={this.showNewReplan}
							addDataTask={this.addDataTask}
						/>
					)}

					{data.tasks.length === 0 && (<div className={"col-md-12 text-center my-3"}>Aucune VDR trouvée</div>)}
				</div>
			</React.Fragment>
		)
	}
}

// export CheckOrTime;
export default connect(mapStateToProps, mapDispatchToProps)(TaskList);
