import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faPlus,
  faFileExport,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import {
  setAuthAction,
  startLoading,
  doneLoading,
  closeFlash,
  setFlash,
} from "../../Redux/actions/index";
import api from "../../Utils/Api";
import { connect } from "react-redux";
import Pagination from "../../Utils/Pagination";
import debounce from "lodash/debounce";
import Constants from "../../Utils/Constants";
import DeletePlanOverlay from "../../Components/Addresses/SiteList/DeletePlanOverlay";
import FiltersWrap from "../../Utils/Filter/FiltersWrap";
import FilterElement from "../../Utils/Filter/FilterElement";
import { buildFilters } from "../../Utils/Helpers";
import Modal from "react-bootstrap/Modal";

const remote = Constants.apiURL; // process.env.REACT_APP_REMOTE_URL ? process.env.REACT_APP_REMOTE_URL : 'http://localhost/witrack-rollout-backend/api/public/';

const status = [
  { label: "Accepter", value: "Accepted" },
  { label: "Rejeter", value: "Rejected" },
];
const mapStateToProps = (state) => {
  return {
    auth: state.session.auth,
    user: state.session.user,
    filters: state.filters,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    isLoading: (e) => dispatch(startLoading(e)),
    doneLoading: () => dispatch(doneLoading()),
    setAuth: (e) => dispatch(setAuthAction(e)),
    setFlash: (f) => dispatch(setFlash(f)),
    closeFlash: (f) => dispatch(closeFlash(f)),
  };
}

class PlanningList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sites: [],
      managers: [],
      planning: [],
      showOverlay: false,
      current: 1,
      pages: 1,
      limit: 15,
      //total: 0,
      filters: {
        pickup_site_name: "",
        drop_site_name: "",
        task_type: "",
        agent_username: "",
        date_debut: "",
        date_fin: "",
      },
      plan_start: "",
      plan_end: "",
      showOverlayPlan: false,
      selected_site: 0,
      planIdSelected: 0,
      planSelected: {},
      exportData: [],
      showOwnerOverlay: false,
      showDeletePlanOverlay: false,
      selected_plan: "",
      data: {},
      planning: [],
      showModal: false,
      showEditStatus: false,
      agents: [],
    };

    props.isLoading({
      message: "Chargement du planning",
      animated: true,
    });

    this.loadSites = debounce(this.loadSites, 250);
  }

  changeDate = (name, event) => {
    console.log(event.target.value);
    const that = this;

    this.setState(
      {
        ["plan_" + name]: event.target.value,
      },
      function () {
        that.props.isLoading({
          message: "Mise à jours de la liste",
          animated: true,
        });
        that.loadSites(1);
      }
    );
  };

  changeLimit = (e) => {
    this.props.isLoading();
    this.setState(
      {
        limit: e.value,
        current: 1,
      },
      () => this.loadSites()
    );
  };

  selectPage = (p) => {
    this.props.isLoading({
      message: "Chargement de la page " + p,
      animated: true,
    });

    this.setState({ current: p });

    this.loadSites(p);
  };

  updateFilter = (event, name) => {
    let that = this;
    const value = event.target ? event.target.value : event.value;

    this.setState(
      {
        filters: {
          ...this.state.filters,
          [name]: value,
        },
      },
      function () {
        console.log(that.state.filters);
        that.props.isLoading({
          message: "Mise à jours de la liste",
          animated: true,
        });

        that.loadSites(1);
      }
    );
  };

  updateFilterCheck = (event, name) => {
    let that = this;
    const value = event.target ? event.target.checked : false;

    this.setState(
      {
        filters: {
          ...this.state.filters,
          [name]: value,
        },
      },
      function () {
        console.log(that.state.filters);
        that.props.isLoading({
          message: "Mise à jours de la liste",
          animated: true,
        });

        that.loadSites(1);
      }
    );
  };

  loadSites = (p = this.state.current) => {
    let that = this;

    const crits = buildFilters(this.state.filters);

    // .get("/api/sites/planning/"+this.props.match.params.id+"/"+this.state.selected_type)
    // api.get("/api/sites/fetch/filter?limit="+this.state.limit+"&page=" + p + "&planning&" + crits)
    // .then(function(response) {
    //   if (response.data.success) {
    //     console.log("fetching response sites",response.data);
    //     that.setState({
    //       sites: response.data.sites,
    //       pages: response.data.pages,
    //       total: response.data.total,
    //     });
    //   } else {
    //     if (response.data.errcode === 101) {
    //       that.props.setAuth(false);
    //     }
    //   }

    //   that.props.doneLoading();
    // });

    api
      .get(
        "/api/V2/planning/fetch?limit=" +
          this.state.limit +
          "&page=" +
          p +
          "&planning&" +
          crits
      )
      .then(function (response) {
        if (response.data.success) {
          console.log("fetching response sites", response.data);
          that.setState({
            planning: response.data.planning,
            pages: response.data.pages,
            total: response.data.total,
          });
        } else {
          if (response.data.errcode === 101) {
            that.props.setAuth(false);
          }
        }

        that.props.doneLoading();
      });
  };

  componentDidMount() {
    this.loadSites();
  }

  toggleOverlay = (e) => {
    e.preventDefault();
    this.setState({ showOverlay: !this.state.showOverlay });
  };

  // toggleOverlayPlan = (siteId) => {
  //   this.setState({
  //     selected_site: siteId,
  //     showOverlayPlan: !this.state.showOverlayPlan,
  //   });
  // };

  toggleOverlayPlan = (planId, plan) => {
    this.setState({
      planIdSelected: planId,
      planSelected: plan,
      agents: plan ? plan.agents : [],
      showOverlayPlan: !this.state.showOverlayPlan,
    });
  };

  editPlan = (e) => {
    e.preventDefault();

    const formdata = new FormData(e.target);

    let data = {};

    for (var [key, value] of formdata.entries()) data[key] = value;

    data["plan_id"] = this.state.planIdSelected;
    data["agents"] = this.state.agents;
    const that = this;

    this.toggleOverlayPlan(0);
    this.props.isLoading({
      message: "Mise à jour de la demande en cours",
      animated: true,
    });

    api.post("/api/planning/edit", data).then(function (response) {
      that.props.doneLoading();

      if (response.data.success) {
        that.loadSites(1);
        //window.location.reload();
        that.props.setFlash({
          active: true,
          message: "Demande modifié avec succés !",
          classes: "success",
          style: {},
        });
      } else {
        console.log(response.data.error);
        that.props.setFlash({
          active: true,
          // message: "Insertion du Delivery plan echouée !",
          message: response.data.error,
          classes: "danger",
          style: {},
        });
      }
    });
  };
  editStatus = () => {
    let { data } = this.state;
    if (!data.motif || !data.status) {
      this.props.setFlash({
        active: true,
        // message: "Insertion du Delivery plan echouée !",
        message: "Veuillez remplir les champs obligatoires svp !",
        classes: "danger",
        style: {},
      });
      return false;
    }
    const that = this;
    this.props.isLoading({
      message: "Mise à jour de la demande en cours",
      animated: true,
    });

    api
      .post("/api/planning/status/demande/" + this.state.selected_plan, data)
      .then(function (response) {
        that.props.doneLoading();

        if (response.data.success) {
          that.loadSites(1);
          //window.location.reload();
          that.props.setFlash({
            active: true,
            message: "Demande modifié avec succés !",
            classes: "success",
            style: {},
          });
        } else {
          console.log(response.data.error);
          that.props.setFlash({
            active: true,
            // message: "Insertion du Delivery plan echouée !",
            message: response.data.error,
            classes: "danger",
            style: {},
          });
        }
      });
  };

  exportResult = (e) => {
    e.preventDefault();

    // const crits =
    //   "limit=-01" +
    //   "&site=" +
    //   this.state.filters.site +
    //   "&site_wilaya=" +
    //   this.state.filters.site_wilaya +
    //   "&plan_start=" +
    //   this.state.plan_start +
    //   "&plan_end=" +
    //   this.state.plan_end +
    //   "&site_manager=" +
    //   this.state.filters.site_manager +
    //   "&site_cluster_manager=" +
    //   this.state.filters.site_cluster_manager +
    //   "&site_cluster=" +
    //   this.state.filters.site_cluster +
    //   "&have_plan=" +
    //   this.state.filters.have_plan +
    //   "&not_have_plan=" +
    //   this.state.filters.not_have_plan;

    // const action =
    //   remote +
    //   "/api/sites/fetch/filter?export=true&planning=true&token=" +
    //   this.props.user.token +
    //   "&" +
    //   crits;

    const action =
      remote +
      "/api/V2/planning/fetch?limit=-1&export=true&token=" +
      this.props.user.token;

    window.location = action;

    //e.target.submit();
  };

  toggleOwnerOverlay = (siteId) =>
    this.setState({
      selected_site: siteId,
      showOwnerOverlay: !this.state.showOwnerOverlay,
    });

  toggleDeleteOverlay = (siteId) =>
    this.setState({
      selected_site: siteId,
      showDeletePlanOverlay: !this.state.showDeletePlanOverlay,
    });

  cancelPlanning = () => {
    this.props.isLoading({
      message: "Mise à jour du plan PM en cours",
      animated: true,
    });
    api.post("/api/planning/cancel/" + this.state.selected_plan).then((res) => {
      this.props.doneLoading();
      if (res.data.success) {
        this.loadSites(1);
        // window.location.reload();
        this.props.setFlash({
          active: true,
          message: res.data.message,
          classes: "success",
          style: {},
        });
      } else {
        console.log(res.data.error);
        this.props.setFlash({
          active: true,
          // message: "Insertion du Delivery plan echouée !",
          message: res.data.error,
          classes: "danger",
          style: {},
        });
      }
    });
  };

  render() {
    const agents = this.props.filters.agents.map((agent) => ({
      label: agent.label,
      value: agent.label,
    }));
    return (
      <React.Fragment>
        <DeletePlanOverlay
          open={this.state.showDeletePlanOverlay}
          toggle={this.toggleDeleteOverlay}
          site={this.state.selected_site}
          reload={this.loadSites}
          {...this.props}
        />

        <div
          className={this.state.showOverlay ? "ui-overlay open" : "ui-overlay"}
        >
          <div
            className={
              "ui-overlay-content status-overlay-text col-md-4 offset-md-4"
            }
          >
            <button
              data-package-id="0"
              onClick={this.toggleOverlay}
              className={"ui-overlay-close btn btn-danger btn-sm"}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>

            <h5>Insérer un nouveau fichier des demandes</h5>

            <form
              method="post"
              encType={"multipart/form-data"}
              action={Constants.apiURL + "/api/planning/new"}
            >
              <input type="hidden" name="token" value={this.props.user.token} />
              <div className="form-group mt-3">
                <label className={"font-exp"}>
                  Selectionnez le fichier demandes
                  <a
                    href={Constants.apiURL + "/template_planning.xlsx"}
                    className={"btn btn-sm btn-link"}
                    target="_blank"
                  >
                    {" "}
                    (voir template){" "}
                  </a>
                </label>

                <input
                  type="file"
                  id="planning_file"
                  name="planning_file"
                  className={"form-control"}
                />
                <Select
                  name={"planning_manager"}
                  placeholder={"Selectionnez le manager.."}
                  options={this.props.filters.managers}
                  className={"mt-2"}
                />

                <input
                  type="submit"
                  value="Envoyer"
                  className={"btn btn-primary btn-sm mt-5"}
                />
              </div>
            </form>
          </div>
        </div>

        <div
          className={
            this.state.showOverlayPlan ? "ui-overlay open" : "ui-overlay"
          }
        >
          <div
            className={
              "ui-overlay-content status-overlay-text col-md-4 offset-md-4"
            }
          >
            <button
              data-package-id="0"
              onClick={this.toggleOverlayPlan}
              className={"ui-overlay-close btn btn-danger btn-sm"}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>

            <h5>Ajouter une nouvelle demande</h5>

            <form
              onSubmit={this.editPlan}
              method="post"
              encType={"multipart/form-data"}
              //action="http://localhost/witrack-rollout-backend/public/api/planning/edit"
            >
              <input
                type="hidden"
                name="selected_site"
                value={this.state.selected_site}
              />
              <div className="form-group mt-3">
                <label className={"font-exp"}>
                  Renseingez les information de la demande
                </label>

                <input
                  type="date"
                  id="planning_date"
                  name="planning_date"
                  className={"form-control"}
                />

                <Select
                  value={this.state.agents}
                  inputId={"planning_manager"}
                  isMulti
                  //name={"planning_manager"}
                  name={"planning_agent"}
                  placeholder={"Selectionnez l'agent.."}
                  onChange={(e) => this.setState({ agents: e })}
                  options={[
                    // { label: "Tous", value: "" },
                    ...agents,
                  ]}
                  className={"mt-2"}
                />

                <input
                  type="submit"
                  value="Envoyer"
                  className={"btn btn-primary btn-sm mt-5"}
                />
                <div>
                  <small>Attention cette action est irreversible.</small>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className={"container-header"}>
          Demandes
          <Link
            className={"btn btn-primary btn-sm float-right"}
            to={"/calendar"}
          >
            Calendrier
          </Link>
        </div>

        <div className={"row"}>
          <div className={"col-md-2"}>
            <div className={"side-filters"}>
              {this.props.user &&
              (this.props.user.role === "cluster_manager" ||
                this.props.user.role === "admin") ? (
                <React.Fragment>
                  <a
                    href={"/sites"}
                    onClick={this.toggleOverlay}
                    className={"btn btn-primary btn-block mb-2"}
                  >
                    <FontAwesomeIcon icon={faPlus} className={"mr-2"} />
                    Nouvelle demande
                  </a>
                </React.Fragment>
              ) : (
                ""
              )}

              <form
                onSubmit={this.exportResult}
                action="http://localhost/witrack-rollout-backend/public/api/export/csv"
                method="get"
              >
                <input
                  type="hidden"
                  name="token"
                  value={this.props.user.token}
                />
                <button
                  type="submit"
                  className={"btn btn-primary btn-block mb-3"}
                >
                  <FontAwesomeIcon icon={faFileExport} className={"mr-2"} />
                  Exporter le résultat
                </button>
              </form>

              <h4>Recherche</h4>

              <FiltersWrap update={this.updateFilter} session={this.props.user}>
                {/* <FilterElement label={"Pickup Zone name"} name={"pickup_site_name"} /> */}
                <FilterElement label={"Client name"} name={"drop_site_name"} />
                {/* <FilterElement
                  type={"options"}
                  label={"Status"}
                  name={"site_status"}
                  options={this.props.filters.site_status}
                  defaultValue={{
                    label: "Active",
                    value: "active",
                  }}
                /> */}

                <FilterElement
                  role={["admin", "cluster_manager", "manager"]}
                  type={"options"}
                  label={"Ingénieur"}
                  name={"agent_username"}
                  options={[{ label: "Tous", value: "" }, ...agents]}
                />

                <div className={"form-group"}>
                  <label>Type tache</label>
                  <Select
                    classNamePrefix={"custom-select"}
                    options={[
                      { value: "", label: "tout" },
                      { value: "DELIVERY", label: "DELIVERY" },
                      { value: "SSR", label: "SSR" },
                    ]}
                    placeholder={"Selectionnez..."}
                    onChange={(e) => this.updateFilter(e, "task_type")}
                  />
                </div>

                {/* <FilterElement
                  role={"admin"}
                  type={"options"}
                  label={"Cluster"}
                  name={"site_cluster"}
                  options={[
                    { label: "Tous", value: "" },
                    ...this.props.filters.clusters,
                  ]}
                /> */}

                {/* <FilterElement
                  role={["admin", "cluster_manager", "manager"]}
                  type={"options"}
                  label={"Wilaya"}
                  name={"site_wilaya"}
                  options={[
                    { label: "Tous", value: "" },
                    ...this.props.filters.wilayas,
                  ]}
                /> */}

                {/* <FilterElement
                  role={["admin", "cluster_manager", "manager"]}
                  type={"options"}
                  label={"Site Owner"}
                  name={"site_owner"}
                  options={this.props.filters.site_owners}
                /> */}

                {/* <FilterElement
                  role={["admin", "cluster_manager", "manager"]}
                  type={"options"}
                  label={"Manager"}
                  name={"site_manager"}
                  options={[
                    { label: "Tous", value: "" },
                    ...this.props.filters.managers,
                  ]}
                /> */}

                {/* <FilterElement
                  type={"radio"}
                  name={"has_plan"}
                  options={[
                    { label: "Tous", value: "", default: true },
                    { label: "Non Planifié", value: false },
                    { label: "Planifié", value: true },
                  ]}
                /> */}
              </FiltersWrap>

              <div className={"form-group"}>
                <label>Début</label>
                <input
                  onChange={(e) => this.updateFilter(e, "date_debut")}
                  type="date"
                  className={"form-control"}
                />
              </div>

              <div className={"form-group"}>
                <label>Fin</label>
                <input
                  onChange={(e) => this.updateFilter(e, "date_fin")}
                  type="date"
                  className={"form-control"}
                />
              </div>
            </div>
          </div>

          <div className={"col-md-10"}>
            <div className={"row mb-3"}>
              <div className={"col-md-6 items-found"}>
                <p>
                  <b>{this.state.total}</b> Demandes trouvés
                </p>
              </div>
              <div className={"col-md-6 per-page-wrap"}>
                <div className={"per-page-content"}>
                  <p>Résultat par page</p>
                  <Select
                    classNamePrefix={"custom-select"}
                    onChange={this.changeLimit}
                    className={"per-page nm"}
                    options={this.props.filters.result_per_page}
                    value={{ label: this.state.limit, value: this.state.limit }}
                  />
                </div>
                <Pagination data={this.state} handleClick={this.selectPage} />
              </div>
              {/* <Select
                classNamePrefix={"custom-select"}
                options={this.props.task_types}
                defaultValue={this.props.task_types.filter(item => item.value === this.state.selected_type)}
                onChange={this.changeTaskType}
            /> */}
            </div>

            <div className={"row"}>
              <div className={"col-md-12"}>
                <div className="col-md-12 ctable mb-3">
                  <div className={"d-none d-sm-block"}>
                    <div className={"row head-titles ctable-row"}>
                      {/* <div className={"col-md-2"}>Pickup site</div> */}

                      {/* <div className={"col-md-3"}>Task type</div> */}

                      <div className={"col-md-1"}>Sites</div>
                      <div className={"col-md-1"}>Date Creation</div>

                      {/* <div className={"col-md-2"}>Wilaya</div> */}

                      {/* <div className={"col-md-1"}>Owner</div> */}
                      <div className={"col-md-2"}> Ingénieurs </div>

                      <div className={"col-md-1"}>Date Planification</div>

                      <div className={"col-md-2"}>Type Demande</div>
                      <div className={"col-md-1"}>Status Demande</div>

                      <div className={"col-md-4 task-details-col"}>
                        <Trans i18nKey="label_details" />
                      </div>
                    </div>
                  </div>

                  {this.state.planning.map((plan, i) => (
                    <React.Fragment key={i}>
                      <div className={"row "}>
                        <div className={"col-md-12"}>
                          <div className={"row ctable-row"}>
                            {/* <div className={"col-md-2"}>{plan.pickup_site_name}</div> */}
                            {/* <div className={"col-md-1"}>{site.type}</div> */}

                            <div className={"col-md-1"}>
                              {plan.drop_site_name}
                            </div>
                            <div className={"col-md-1"}>{plan.created_at}</div>
                            {/* <div className={"col-md-2"}>
                              {plan.pickup_site_address_wilaya.toUpperCase()}
                            </div> */}

                            {/* <div className={"col-md-1"}>{plan.site_owner}</div> */}
                            <div className={"col-md-2"}>
                              <span
                                className="truncateLongTexts"
                                title={plan.agents_username}
                              >
                                {plan.agents_username}
                              </span>{" "}
                            </div>

                            <div className={"col-md-1"}>{plan.plan_date}</div>

                            <div className={"col-md-2 site-plan-col-date"}>
                              {/* <span>{plan.task_type}</span> */}
                              <span>{plan.type_demande}</span>
                            </div>

                            <div className={"col-md-1"}>
                              <span>
                                {plan.status_demande
                                  ? plan.status_demande
                                  : "En cours"}
                              </span>
                            </div>

                            <div className={"col-md-4 task-details-col"}>
                              {/* {plan.plan_date ? (
                                <button
                                  onClick={() =>
                                    this.toggleDeleteOverlay(plan.planid)
                                  }
                                  className={"task-action-btn"}
                                >
                                  Delete PM Plan
                                </button>
                              ) : (
                                ""
                              )} */}

                              <button
                                onClick={() =>
                                  this.setState({
                                    showEditStatus: true,
                                    selected_plan: plan.planid,
                                  })
                                }
                                className={"task-action-btn"}
                              >
                                Action
                              </button>
                              <button
                                onClick={() =>
                                  this.toggleOverlayPlan(plan.planid, plan)
                                }
                                className={"task-action-btn"}
                              >
                                Planifier
                              </button>

                              <button
                                onClick={() =>
                                  this.setState({
                                    showModal: true,
                                    selected_plan: plan.planid,
                                  })
                                }
                                className={"task-action-btn"}
                              >
                                Annuler
                              </button>

                              {/* <div>

                                    <a target="_blank" href={"https://www.google.com/maps/place/"+address.store_position.lat+","+address.store_position.lng} className={"btn btn-block btn-sm btn-primary"}>Map</a>
                                    <button  onClick={toggleHidden} data-row-index={i}  className={"btn btn-block btn-sm btn-primary"}>Photos </button>
                                    </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}

                  {this.state.planning.length === 0 && (
                    <div className={"col-md-12 text-center my-3"}>
                      Aucune demande trouvé
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <Modal
            show={this.state.showModal}
            onHide={() => this.setState({ showModal: false })}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>êtes-vous sûr de vouloir supprimer </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <button
                className={"btn btn-danger"}
                onClick={() => this.setState({ showModal: false })}
              >
                Close
              </button>
              <button
                onClick={() => this.cancelPlanning()}
                className={"btn btn-success"}
              >
                Confirmer
              </button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={this.state.showEditStatus}
            onHide={() => this.setState({ showEditStatus: false })}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Modifier le status de la demande</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group mt-3">
                <label className={"font-exp"}>Choisissez le statu *</label>

                <Select
                  inputId={"status"}
                  //name={"planning_manager"}
                  name={"status"}
                  placeholder={"Selectionnez le status.."}
                  //options={this.props.filters.managers}
                  options={status}
                  className={"mt-2"}
                  onChange={(e) =>
                    this.setState({
                      data: { ...this.state.data, status: e.value },
                    })
                  }
                />

                <label className={"font-exp mt-2"}>Motif *</label>
                <input
                  type="text"
                  id="motif"
                  name="motif"
                  className={"form-control mt-2"}
                  onChange={(e) =>
                    this.setState({
                      data: { ...this.state.data, motif: e.target.value },
                    })
                  }
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className={"btn btn-danger"}
                onClick={() => this.setState({ showEditStatus: false })}
              >
                Close
              </button>
              <button
                onClick={() => this.editStatus()}
                className={"btn btn-success"}
              >
                Confirmer
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanningList);
