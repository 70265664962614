import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import PhotoItem from "../../../../Utils/PhotoItem";

const moment = require("moment");

const Maintenance = ({ data, active, ...props }) => {
  console.log("data===>", Object.keys(data), data.maintenance, data.site);
  const url =
    data && data.location
      ? `https://www.google.com/maps/place/${data.location.lat},${data.location.lng}/@${data.location.lat},${data.location.lng},15z`
      : "";

  if (data)
    return (
      <>
        <h6>Maintenance (Fiche Technique)</h6>
        <br />
        <h6>Orgaisation De La Maintenance</h6>
        <div className={"row col-md"}>
          <label>Equipe De Maintenance : </label>
          {data.maintenance.equipe}
          {data.maintenance.equipe == "Existence" && (
            <>
              <div className={"row col-md"}>
                <label>Structure : </label>
                {data.maintenance.Structure}
                <label>Effectif : </label>
                {data.maintenance.Effectif}
                <label>Profil : </label>
                {data.maintenance.Profil}
              </div>
            </>
          )}
        </div>
        <div className={"row col-md"}>
          <label>Gestion : </label>
          {data.maintenance.Gestion}
        </div>

        <h6>Phylosophie De La Maintenance</h6>
        <div className={"row col-md"}>
          <label>Type De Maintenance : </label>
          {data.maintenance.Profil}
        </div>
        <div className={"row col-md"}>
          <label>Selon QUi : </label>
          {data.maintenance.Gestion}
        </div>
        <h6>Principaux Controles Effectues</h6>
        <div className={"row col-md"}>
          <label>Service Concerné : </label>
          {data.maintenance.Service_concerne}
        </div>
        <div className={"row col-md"}>
          <label>Base du Planning : </label>
          {data.maintenance.Base_planning}
        </div>
        <div className={"row col-md"}>
          <label>Vérification effectuée : </label>
          {data.maintenance.Vérification_effectuee}
        </div>
        <div className={"row col-md"}>
          <label>Régulation effectuée : </label>
          {data.maintenance.Régulation_effectuee}
        </div>
        <div className={"row col-md"}>
          <label>Fréquence : </label>
          {data.maintenance.Frequence}
        </div>
        <h6>
          Recours a la sous traitance et/ou vérification par organisme externe
          agrée
        </h6>
        <div className={"row col-md"}>
          <label>Equipements : </label>
          {data.maintenance.Equipements}
        </div>
        <div className={"row col-md"}>
          <label>Fréquence De vérification : </label>
          {data.maintenance.Fréquence_verification}
        </div>
        <div className={"row col-md"}>
          <label>Organisme De De vérification : </label>
          {data.maintenance.Oragnisme_verification}
        </div>
        <div className={"row col-md"}>
          <label>Base De De vérification : </label>
          {data.maintenance.Base_verification}
        </div>
        <h6>Arret Annuel</h6>
        <div className={"row col-md"}>
          <label>Arret Annuel : </label>
          {data.maintenance.arret_annuel}
        </div>
        <div className={"row col-md"}>
          <label>Nature Arret : </label>
          {data.maintenance.Nature_arret}
        </div>
        <div className={"row col-md"}>
          <label>Durée Arret : </label>
          {data.maintenance.Duree_arret}
        </div>
        <div className={"row col-md"}>
          <label>Base de l' Arret : </label>
          {data.maintenance.Base_arret}
        </div>
        <div className={"row col-md"}>
          <label>Objectif de l' Arret : </label>
          {data.maintenance.objectif_arret}
        </div>
        <div className={"row col-md"}>
          <label>piece rechange : </label>
          {data.maintenance.piece_rechange}
        </div>
        <div className={"row col-md"}>
          <label>piece rechange : </label>
          {data.maintenance.piece_rechange}
        </div>
        <div className={"row col-md"}>
          <label>Nombre de magasin : </label>
          {data.maintenance.nbr_magasin}
        </div>
        <div className={"row col-md"}>
          <label>Nature de PDR : </label>
          {data.maintenance.Nature_PDR}
        </div>
        <div className={"row col-md"}>
          <label>Gestion de PDR : </label>
          {data.maintenance.Gestion_PDR}
        </div>
        <div className={"row col-md"}>
          <label>Disponibilité de PDR : </label>
          {data.maintenance.Disponibilite_PDR}
        </div>
        <div className={"row col-md"}>
          <label>Origine des PDR : </label>
          {data.maintenance.Origine_PDR}
        </div>
      </>
    );
  else return <>Didn't clock in</>;
};

export default Maintenance;
