import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import PhotoItem from "../../../../Utils/PhotoItem";

const moment = require("moment");

const Introduction = ({ data, active, ...props }) => {
  console.log("data===>", Object.keys(data), data.introduction, data.site);
  const url =
    data && data.location
      ? `https://www.google.com/maps/place/${data.location.lat},${data.location.lng}/@${data.location.lat},${data.location.lng},15z`
      : "";

  if (data)
    return (
      <>
        <b>Agent :</b> {moment(data.finished_at).format("YYYY-MM-DD HH:mm:ss")}
        {data.introduction && (
          <a href={url} className={"ml-2"} target="_blank">
            {data.introduction.mandant}
          </a>
        )}
        <h6>Introduction (Fiche Technique)</h6>
        <br />
        <div className={"row col-md"}>
          <label>Mandant : </label>
          {data.introduction.mandant}
        </div>
        <div className={"row col-md"}>
          <label>Assure : </label>
          {data.introduction.assure}
        </div>
        <div className={"row col-md"}>
          <label>Adresse : </label>
          {data.introduction.Adresse}
        </div>
        <div className={"row col-md"}>
          <label>wilaya : </label>
          {data.introduction.wilaya}
        </div>
        <div className={"row col-md"}>
          <label>Date de Visite : </label>
          {data.introduction.dateVisite}
        </div>
        <div className={"row col-md"}>
          <label>DRef Appel d'offre : </label>
          {data.introduction.refOffre}
        </div>
        <div className={"row col-md"}>
          <label>DRef Appel d'offre : </label>
          {data.introduction.objAssurance}
        </div>
        <div className={"row col-md"}>
          <label>Code site : </label>
          {data.site.site_code}
        </div>
        <div className={"row col-md"}>
          <label>Nom du site : </label>
          {data.site.site_name}
        </div>
      </>
    );
  else return <>Didn't clock in</>;
};

export default Introduction;
