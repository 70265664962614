import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import PhotoItem from "../../../../Utils/PhotoItem";

const moment = require("moment");

const situation = ({ data, active, ...props }) => {
    console.log("data===>",Object.keys(data),data.situation,data.site)
  const url = data && data.location ? `https://www.google.com/maps/place/${data.location.lat},${data.location.lng}/@${data.location.lat},${data.location.lng},15z` : "";

  if (data)
    return (
      <>
        
         <h6>Situation</h6>
        <br />
        <h6> Situation DU RISQUE </h6>
       
      <div className={"row col-md-6"}>
        <label>Localisation  :  </label>
        {data.situation.localisation}
      </div>

 
      <div className={"row col-md-6"}>
        <label>Adresse  :  </label>
        {data.situation.Adresse}
      </div>
      <div className={"row col-md-6"}>
        <label>Commune:  </label>
        {data.situation.Commune}
      </div> 
      <div className={"row col-md-6"}>
        <label>Wilaya :  </label>
        {data.situation.wilaya}
      </div>
      <div className={"row col-md-6"}>
        <label>Proximité au zone urbaine:  </label>
        {data.situation.zoneUrbaine}
      </div> 
      <h6>Coordonnées géographiques</h6>
      <div className={"row col-md-6"}>
        <label>Latitude :  </label>
        {data.situation.latitude}
      </div> 
      <div className={"row col-md-6"}>
        <label>Altitude(m) : </label>
        {data.situation.altitude}
      </div>
      <div className={"row col-md-6"}>
        <label>Longitude : </label>
        {data.situation.longitude}
      </div>
      <h6>Voisinage</h6>
      <div className={"row col-md-6"}>
        <label>A l'Est : </label>
        {data.situation.est}
      </div>
      <div className={"row col-md-6"}>
        <label>A l'Ouest : </label>
        {data.situation.ouest}
      </div>
      <div className={"row col-md-6"}>
        <label>Au Nord : </label>
        {data.situation.nord}
      </div>
      <div className={"row col-md-6"}>
        <label>Au Sud : </label>
        {data.situation.sud}
      </div>
      
      <div className={"row col-md-6"}>
        <label>Au Nord-Est : </label>
        {data.situation.nordEst}
      </div>
      <div className={"row col-md-6"}>
        <label>Au Nord-Ouest: </label>
        {data.situation.nordOuest}
      </div>
      <div className={"row col-md-6"}>
        <label>Au Sud-Est : </label>
        {data.situation.SudEst}
      </div>
      <div className={"row col-md-6"}>
        <label>Au Sud-Ouest : </label>
        {data.situation.SudOuest}
      </div>
      <h6>Autres voisinage - Lignes électriques</h6>
       
      {data.situation.exsistelignesElectrique=="Existence" &&
      <>
      <div className={"row col-md-6"}>
        <label> Lignes électriques: </label>
        {data.situation.lignesElectrique}
      </div>
      </>
    }
    {data.situation.existeconduitesSouterraines &&
    
       
      <div className={"row col-md-6"}>
        <label>Conduites souterraines  : </label>
        {data.situation.conduitesSouterraines}
      </div>
    
    }
    {data.situation.exsistelOueds && 
     
    
      <div className={"row col-md-6"}>
        <label>Oueds: </label>
        {data.situation.oueds}
      </div>
    
    }  
    <h6>Tiers à haut risque</h6>

   {data.situation.exsisteHautRisque && 
   <>
     
      <div className={"row col-md-6"}>
        <label>Activité : </label>
        {data.situation.activite}
      </div>

      <div className={"row col-md-6"}>
        <label>Distance : </label>
        {data.situation.distanceTiers}
      </div>
      </>
    }
    <h6>CLIMAT</h6>
     <div className={"row col-md-6"}>
        <label>wilaya : </label>
        {data.situation.wilayaclima}
      </div>
      <div className={"row col-md-6"}>
        <label>Classification : </label>
        {data.situation.classification}
      </div>

      <div className={"row col-md-6"}>
        <label> Précipitation Moyenne Annuelle (mm): </label>
        {data.situation.precipitation}
      </div>
      <div className={"row col-md-6"}>
        <label>La vitesse des vents : (Direction des vents) : </label>
        {data.situation.vitesseVents}
      </div>
      <div className={"row col-md-6"}>
        <label>Température minimale (..°C)  : </label>
        {data.situation.temperatureminimale}
      </div>
      <div className={"row col-md-6"}>
        <label>Température maximale (..°C)  : </label>
        {data.situation.temperaturemaximale}
      </div>
      


      </>
    );
  else return <>Didn't clock in</>;
};

export default situation;
