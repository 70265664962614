import React, { useEffect , useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck, faFileExport } from "@fortawesome/free-solid-svg-icons";
import PhotoItem from "../../../../Utils/PhotoItem";
import {convertDMSToDD} from '../../../../Utils/Helpers'
import {isJsonString} from '../../../../Utils/Helpers'
import Modal from 'react-bootstrap/Modal'
import { MapContainer, TileLayer, Marker, Popup , GeoJSON} from 'react-leaflet';
import "leaflet/dist/images/marker-shadow.png";
import ReactLeafletKml from 'react-leaflet-kml';
import Constants from '../../../../Utils/Constants';
import { useSelector } from 'react-redux';


const moment = require("moment");

const capacity = Array(30).fill(0);
const countC = [3, 7, 7, 9, 9]


const SubSite = ({ data, active, geo, onchange, id, task, code,cosite,  ...props }) => {
    //console.log('this ma[[' + JSON.stringify(cosite))
    const { session } = useSelector(state => ({ session: state.session }));
    const [show, setShow] = useState(false);
    const [selectedTable, setSelectedTable] = useState('');
    const [count, setCount] = useState(0);
    const [selectedItem, setSelectedItem] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const exportForm = useRef(null);
    const detailInput = useRef(null);

    // const [items, setItems] = useState(data.items);
    // const [items_rad_ant, setItemsRadAnt] = useState(data.items_rad_ant);
    // const [items_trans, setItemsTrans] = useState(data.items_trans);
    // const [items_mast, setItemsMast] = useState(data.items_mast);
    // const [items_mast2, setItemsMast2] = useState(data.items_mast2);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const exportPMBatXLSX = () => {
      console.log('aef')
      //setLoading(true);
    
      exportForm.current.action = Constants.apiEndPoints.EXPORTXLSXZONE_SSR_TASK(9);
      exportForm.current.submit();
    }



  return (
    <>
     <form method="post" ref={exportForm} target={"_blank"} >
        <input type="hidden" name={"pm"} ref={detailInput} />
        <input type="hidden" name={"token"} value={session.user.token} />
      </form>
   
     <h6>Informations générales (Fiche Technique)</h6>
      <div className={"row col-md-6"}>
        <label>Date de début de la tâche  : </label>
        {data.ssr_start_date}
      </div>

      <div className={"row col-md-6"}>
        <label>Heure de début de la tâche : </label>
        {data.ssr_start_time}
      </div>

      <div className={"row col-md-6"}>
        <label>Date de fin de la tâche : </label>
        {moment(data.finished_at).format("YYYY-MM-DD")}
      </div>

      <div className={"row col-md-6"}>
        <label>Heure de fin de la tâche : </label>
        {moment(data.finished_at).format("HH:mm:ss")}
      </div>


   



      <label>Item : </label>
      {data.Item.length > 0 &&  <div className={"col-md-12"}>
          <div className="row border">
              <div className="col-md-2"><b>Nom Item</b></div>
              <div className="col-md-2"><b>Code Inventaire</b></div>
              <div className="col-md-2"><b>Numéro Comptable</b></div>
              <div className="col-md-2"><b>Etat de l'Item</b></div>
              <div className="col-md-2"><b>Valeur</b></div>
              <div className="col-md-2"><b>Photos</b></div>
          </div>
          
          {data.Item.map(item => (
            <div className="row border">
              <div className="col-md-2">{item.name_item ? item.name_item : '' }</div>
              <div className="col-md-2">{item.code_item ? item.code_item : '' }</div>
              <div className="col-md-2">{item.nbr_compt ? item.nbr_compt : '' }</div>
              <div className="col-md-2">{item.etat_item ? item.etat_item : '' }</div>
              <div className="col-md-2">{item.val_item ? item.val_item : '' }</div>
              <div className="col-md-2">{item.photos && item.photos.length > 0 ? <PhotoItem photo={item.photos[0]}  /> : '/' }</div>
            </div>
          ))}
      </div>}



    </>
  );
};

export default SubSite;
